import React, { useState } from 'react';
import { DatePicker, Form } from 'antd';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import moment from 'moment';

export const localeInDatepicker = {
    ...locale,
    lang: {
        ...locale.lang,
        locale: "ja_JP",
        placeholder: '日付を選択',
        today: '今日',
        now: '現在時刻',
        month: '月',
        year: '年',
        day: '日',
        monthBeforeYear: false,
        shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
        shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月',
            '7月', '8月', '9月', '10月', '11月', '12月'],
    },
}
export const DatePickerWithTimeInForm = ({initialTime, label, name, disableDate, disabled=false, border={}, style={}, showTime={ defaultValue: moment('00:00', 'hh:mm A'), use12Hours: true }, format="YYYY-MM-DD hh:mm A", tenMinRule=false}) => {
    const [open, setOpen] = useState(false);
    const validateDate = (_, value) => {
        if (!value) {
          return Promise.reject(new Error('日付を入力してください'));
        }
        const minutes = moment(value).minutes();
        if (tenMinRule && (minutes % 10 !== 0 || minutes >= 51)) {
          return Promise.reject(new Error('分は10分単位で入力してください'));
        }
        return Promise.resolve();
    };

    return (
        <Form.Item
            style={style}
            label={label}
            name={name}
            initialValue={initialTime ? moment(initialTime) : null}
            validateFirst
            rules={[{ required: true, message: `${label}を入力してください。` }, { validator: validateDate }]}
            onClick={(e) => !open && setOpen(true)}
        >
            <DatePicker
                locale={localeInDatepicker}
                showTime={showTime}
                disabledDate={(current) => {
                    if (disableDate) {
                        return disableDate(current)
                    }
                    return current?.isBefore(moment().add(-1, 'days')) 
                }}
                style={border}
                minuteStep={10}
                format={format}
                disabled={disabled}
                inputReadOnly={true}
                open={open}
                onOpenChange={setOpen}
            />
        </Form.Item>
    )
}
