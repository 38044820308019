import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Button,
    Card,
    Space,
    Typography,
    Table,
    Switch,
    message,
    Skeleton,
    Spin,
    Progress,
    Tooltip
} from 'antd';
import { WarningFilled, CheckCircleFilled, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { GET_STATIONS, GET_LABEL_DATA_PERCENTAGE, GET_FORECAST_MODELS} from '../../queries';
import { CREATE_FORECAST_MODEL, UPDATE_FORECAST_MODEL } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import momentify from '../../Utilities/momentify';
import moment from 'moment';

const ForecastModel = () => {
    const currentUser = authenticationService.currentUserValue
    const { loading: loadingStations, data: stationsData } = useQuery(GET_STATIONS, {
        fetchPolicy: 'cache-and-network',
    });
    const { loading: loadingModels, data: forecastModelData, refetch: refetchForecastModels } = useQuery(GET_FORECAST_MODELS, {
        fetchPolicy: 'network-only',
    });

    const { loading: loadingLabelPercent, data: labelDataPercentage } = useQuery(GET_LABEL_DATA_PERCENTAGE, {
        variables: { companyId: currentUser.company_id }, // Replace `yourCompanyId` with the actual company ID
        fetchPolicy: 'no-cache',
    });

    const [rows, setRows] = useState([]);
    const [createForecastModel] = useMutation(CREATE_FORECAST_MODEL);
    const [updateForecastModel] = useMutation(UPDATE_FORECAST_MODEL);
    const [loadingRow, setLoadingRow] = useState({});
    
    useEffect(() => {
        if (!loadingLabelPercent && !loadingModels && labelDataPercentage?.labelDataPercentage) {
            const updatedRows = labelDataPercentage.labelDataPercentage.map((item) => {
                const { stationId, labelDataPercentage } = item;
    
                // Find the existing model for this station
                const existingModel = forecastModelData?.forecastModels?.edges?.find(
                    (edge) => String(edge.node.stationId) === String(stationId)
                );
    
                return {
                    station: stationId,
                    progress: labelDataPercentage,
                    updatedAt: existingModel?.node?.updatedAt || null,
                    isModelAvailable: !!existingModel,
                    isLabelData: existingModel?.node?.isLabelData || null,
                    use_forecast: existingModel?.node?.useForecast ?? false,
                    use_fixed_route: existingModel?.node?.useFixedRoute ?? false,
                };
            }).sort((a, b) => a.station - b.station);
    
            setRows(updatedRows);
        }
    }, [loadingLabelPercent, loadingModels, labelDataPercentage, forecastModelData]);
    

    const handleExecuteForecastModel = async (stationId, useForecast, useFixedRoute, isLabelData) => {
        setLoadingRow((prev) => ({ ...prev, [stationId]: true }));
    
        const existingModel = forecastModelData?.forecastModels?.edges?.find(
            (edge) => String(edge.node.stationId) === String(stationId)
        );
    
        try {
            if (existingModel) {
                await updateForecastModel({
                    variables: {
                        input: {
                            id: existingModel.node.id,
                            useForecast,
                            useFixedRoute,
                            isLabelData,
                        },
                    },
                    update: (cache, { data }) => {
                        if (!data?.updateForecastModel?.forecastModel) return;
    
                        // Update Apollo cache directly
                        const updatedModel = data.updateForecastModel.forecastModel;
                        cache.modify({
                            id: cache.identify(existingModel.node),
                            fields: {
                                useForecast: () => updatedModel.useForecast,
                                useFixedRoute: () => updatedModel.useFixedRoute,
                            },
                        });
                    },
                });
    
                message.success('予測モデルが更新されました。');
            } else {
                await createForecastModel({
                    variables: {
                        input: {
                            stationId,
                            useForecast: true, // Ensure 自動予測 is on when the schedule is first turned on
                            useFixedRoute, 
                            isLabelData,
                        },
                    },
                    update: (cache, { data }) => {
                        if (!data?.createForecastModel?.forecastModel) return;
    
                        const newModel = data.createForecastModel.forecastModel;
                        const newEdge = { __typename: "ForecastModelEdge", node: newModel };
    
                        // Merge the new model into Apollo cache
                        cache.modify({
                            fields: {
                                forecastModels(existingModels = { edges: [] }) {
                                    return {
                                        ...existingModels,
                                        edges: [...existingModels.edges, newEdge],
                                    };
                                },
                            },
                        });
                    },
                });
    
                message.success('予測モデルが作成されました。');
            }
        } catch (error) {
            message.error('予測モデルの処理に失敗しました。');
            console.error(error);
        } finally {
            setLoadingRow((prev) => ({ ...prev, [stationId]: false }));

            // **Refetch only if cache update is not enough**
            await refetchForecastModels();
        }
    };
    

    const columns = [
        {
            title: <Typography.Text data-testid='table-col-station' strong>営業所</Typography.Text>,
            dataIndex: 'station',
            width: '15%',
            render: (station) => {
                const stationData = stationsData?.stations?.edges?.find(
                    (edge) => parseInt(edge.node.id) === parseInt(station)
                );
                return (
                    <Typography.Text>
                        {stationData ? stationData.node.officialName : '不明な営業所'}
                    </Typography.Text>
                );
            }
        },
        {
            title: (
                <Space>
                    <Typography.Text data-testid="table-col-status" strong>ラベルデータ</Typography.Text>
                    <Tooltip title="スケジュール作成を実行するためには直近１ヶ月のラベルデータの割合が90%以上必要です">
                        <QuestionCircleOutlined style={{ fontSize: '14px', color: '#1890ff', cursor: 'pointer' }} />
                    </Tooltip>
                </Space>
            ),
            width: '40%',
            render: (record) => {
                const isHighProgress = record.progress >= 90;
        
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '200px' }}>
                        {/* Progress Bar */}
                        <div style={{ flexGrow: 1 }}>
                            <Progress
                                percent={record.progress}
                                steps={20}
                                status="active"
                                showInfo={false} // Disable built-in percentage display
                            />
                        </div>
                        {/* Icon */}
                        {isHighProgress ? (
                            <CheckCircleFilled style={{ color: 'green', fontSize: '16px' }} />
                        ) : (
                            <WarningFilled style={{ color: 'red', fontSize: '16px' }} />
                        )}
                        {/* Percentage Display */}
                        <Typography.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                            {record.progress}%
                        </Typography.Text>
                    </div>
                );
            },
        },
        {
            title: <Typography.Text data-testid="table-col-status" strong>予測オプション</Typography.Text>,
            width: '20%',
            align: 'center',
            render: (record) => {
                const isHighProgress = record.progress >= 90;
                const isModelUnavailable = !record.isModelAvailable;
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
                        {/* 固定ルート Toggle with Tooltip */}
                        <Tooltip title={isModelUnavailable ? "予測モデルを開始してください" : ""}>
                            <Switch
                                checked={record.use_fixed_route}
                                onChange={(checked) => {
                                    if (!checked && !record.use_forecast) {
                                        message.warning("少なくとも1つの予測オプションを有効にしてください。");
                                        return;
                                    }
                                    handleExecuteForecastModel(record.station, record.use_forecast, checked, isHighProgress);
                                }}
                                checkedChildren="固定ルート ON"
                                unCheckedChildren="固定ルート OFF"
                                disabled={isModelUnavailable} // Disable if no model exists
                            />
                        </Tooltip>
        
                        {/* 自動予測 Toggle with Tooltip */}
                        <Tooltip title={isModelUnavailable ? "予測モデルを開始してください" : ""}>
                            <Switch
                                checked={record.use_forecast}
                                onChange={(checked) => {
                                    if (!checked && !record.use_fixed_route) {
                                        message.warning("少なくとも1つの予測オプションを有効にしてください。");
                                        return;
                                    }
                                    handleExecuteForecastModel(record.station, checked, record.use_fixed_route, isHighProgress);
                                }}
                                checkedChildren="自動予測 ON"
                                unCheckedChildren="自動予測 OFF"
                                disabled={isModelUnavailable} // Disable if no model exists
                            />
                        </Tooltip>
                    </div>
                );
            },
        },        
        {
            title: <Typography.Text data-tetid='table-col-command' strong>ステータス</Typography.Text>,
            align: 'center',
            width: '10%',
            render: (record) => {
                const hasModel = record.isLabelData; 
                const isLoading = loadingRow[record.station]
                const isHighProgress = record.progress >= 90;
                return (
                    <Space>
                        <Button
                            type={hasModel ? 'default' : isHighProgress ? 'primary' : 'default'}
                            style={{
                                borderRadius: "7px", 
                                backgroundColor: hasModel ? 'orange' : undefined,  // Set orange background for 稼働中
                                color: hasModel ? 'white' : undefined, // Ensure text is white for contrast
                                borderColor: hasModel ? 'orange' : undefined, // Maintain border color consistency
                            }}
                            onClick={() => {
                                if (hasModel) {
                                    message.info('モデルはすでに稼働中です');
                                    return;
                                }
                                if (isHighProgress && !isLoading) {
                                    try {
                                        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
                                        message.success('モデル作成・更新中');
                            
                                        // Asynchronous call
                                        handleExecuteForecastModel(record.station, record.useForecast, record.useFixedRoute, isHighProgress);
                            
                                        // Update the rows state
                                        setRows((prevRows) =>
                                            prevRows.map((row) =>
                                                row.station === record.station
                                                    ? { ...row, updatedAt: currentDate }
                                                    : row
                                            )
                                        );
                                    } catch (error) {
                                        message.error('スケジュールの作成に失敗しました');
                                        console.error(error);
                                    }
                                } else {
                                    message.info('ラベルデータが不足しています。ラベルデータを追加してください');
                                }
                            }}
                            disabled = {isLoading}
                        >
                            {isLoading ? (
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
                            ) : (
                                hasModel ? '稼働中' : isHighProgress ? '開始' : 'ラベル不足'
                            )}
                        </Button>
                    </Space>
                )
            }
        },
        {
            title: <Typography.Text data-testid="table-col-updated" strong>スケジュール更新日</Typography.Text>,
            dataIndex: "updatedAt",
            width: '15%',
            render: (updatedAt, record) => (
                <Typography.Text>
                    {record.isModelAvailable
                        ? momentify(updatedAt) // Display the formatted date if the model is available
                        : "スケジュール未作成"}
                </Typography.Text>
            ),
        }
    ];

    if (loadingStations || loadingModels || loadingLabelPercent) {
        return <Skeleton active />;
    }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card bordered={false} style={{padding: '0px 24px 0px 24px'  }}>
                <Table
                    columns={columns}
                    dataSource={rows}
                    pagination={false}
                    bordered
                />
            </Card>
        </Space>
    );
};

export default ForecastModel